<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mdocks-modals :modelName="modelName"></mdocks-modals>
	</div>
</template>

<script>
export default {
	name: 'mdocks-grid',
	components: {},
	data() {
		return {
			modelName: 'mdocks',
			modelColumnDefs: {}
		};
	}
};
</script>
